<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<div class="row">
					<component
						:is="'script'"
						src="https://js.mirai.com/mirai-loader/mirai.loader.js"
						data-cookieconsent="necesary"
						rel="preload"
					/>

					<div data-mirai-engine="mirai_be" :data-lang="locale" data-idhotel="100377333" />
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const qrPanel = ref(null);

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style>
#dp-input-departure,
#dp-input-arrival {
	margin: 3px 15px;
	width: 90%;
	padding: 15px 40px 15px 0;
	height: 50px;
	border: none;
	border-radius: 0;
	border-bottom: 4px solid var(--header-color);
	background: none;
	box-shadow: none;
	color: var(--body-color);
	font-weight: 400;
	font-size: 17px;
}

::placeholder {
	color: #fff;
}

/* stylelint-disable selector-class-pattern */

/* stylelint-enable selector-class-pattern */
</style>

<style lang="scss" scoped>
.qr-section {
	text-align: center;
	margin: 0 auto;
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 0;
	padding: 0;
	float: left;
	width: 100%;
	max-width: 920px;
	z-index: 10;
}

.qr-panel {
	float: none;
	width: auto;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	z-index: 2;
	text-align: center;

	.row {
		width: 100%;
		background: rgb(255 255 255 / 40%);

		> div {
			width: 100%;
		}

		&:last-child {
			backdrop-filter: blur(10px);
			font-size: 14px;

			.column12 {
				text-align: left;
			}

			span {
				margin: 0 5px 0 0;
			}
		}
	}

	.columns {
		padding: 0;
		position: relative;
	}

	.booking > *:first-child::after {
		content: '';
		display: block;
		height: 70%;
		width: 1px;
		background-color: #fff;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	form {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}
}

.qr-panel input,
.qr-panel select {
	margin: 0 !important;
	width: 100% !important;
	padding: 0 !important;
}

.qr-panel .button {
	font-size: 16px;
	letter-spacing: normal;
	height: 60px;
	padding: 15px 40px 15px 20px !important;
	border: none !important;
	width: calc(100% + 1px) !important;
}

:deep(.mx-input-wrapper) {
	input::placeholder {
		color: #fff;
	}
}
</style>
