<template>
	<page-container :page="page" :locale="locale" class="index">
		<heading-image v-if="page.headingImage">
			<hero-image type="fullscreen">
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p>
						<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form :booking-form="defaults[locale].bookingForm" />
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:intro-text="page.introText"
			:content="page.content"
		/>

		<section v-if="defaults[locale].homepage.benefit.length" class="bookingbenefits">
			<div class="row">
				<h2>{{ defaults[locale].homepage.sectionBenefitsTitle }}</h2>
			</div>

			<div class="row">
				<div v-for="benefit in defaults[locale].homepage.benefit" :key="benefit.ID" class="columns column4">
					<div class="mu-checkmark"></div>
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
			<div class="row">
				<div class="columns column12 align-center">
					<book-button class="button book-now cta">
						<span>{{ defaults[locale].website.book }}</span>
					</book-button>
				</div>
			</div>
		</section>

		<promoblocks :items="page.promoblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<review-slider
			v-if="reviews.length"
			:items="reviews"
			:header="defaults[locale].homepage.reviewsHeader"
			:button="defaults[locale].homepage.more"
		/>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.intro .column8 {
	padding: 20px 60px;
}

.mu-checkmark {
	background-image: url('~/assets/images/icon-checkmark.svg');
	margin: 5px;
	width: 45px;
	height: 45px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.bookingbenefits {
	background: var(--secondary-background-color);
	font-family: var(--heading-font-family);
	display: flex;
	flex-direction: column;
	gap: 60px;
	padding-top: 80px;

	.row {
		display: flex;
		justify-content: center;
	}

	.book-now {
		margin: 40px 20px;
	}

	.column4 {
		text-align: left;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 28px;
		color: var(--body-color);
		gap: 20px;

		p {
			line-height: 1.4;
		}

		h2 {
			text-align: center;
		}
	}

	svg {
		color: var(--cta-color);
		font-size: 40px;
	}
}

.blocks-header {
	.column12 {
		flex-flow: column wrap;
	}
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 40%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 0;
	font-size: 11px;

	:deep(span) {
		color: #fff;
		font-size: 2.8em;
		font-family: var(--heading-font-family);
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

@media (max-width: 880px) {
	.hero-content {
		:deep(span) {
			font-size: 3.4rem;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}

	.bookingbenefits {
		padding: 60px 0;

		div {
			padding-right: 15px;
		}

		h2 {
			text-align: center !important;
			font-size: 2.6em !important;
		}

		.column4 {
			justify-content: left;
			margin-left: 10%;
			font-size: 28px;
		}
	}
}
</style>
