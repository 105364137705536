<template>
	<section v-if="items.length" class="main-content review-section">
		<div class="row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="splitbackground" />

		<div class="row">
			<div class="columns column12">
				<div class="slider-buttons-container">
					<div class="slider-buttons">
						<button class="embla-prev" @click="scrollPrev">
							<font-awesome-icon :icon="`fa-light fa-chevron-left`" size="1x" />
						</button>
						<button class="embla-next" @click="scrollNext">
							<font-awesome-icon :icon="`fa-light fa-chevron-right`" size="1x" />
						</button>
					</div>
				</div>
			</div>
			<div class="columns column12">
				<div class="slider-background">
					<div ref="emblaCarousel" class="carousel review-slider">
						<div class="carousel-container">
							<div v-for="review in items" :key="review.ID" class="review-slide-content">
								<p>{{ review.content }}</p>
								<div class="border" />
								<div class="source">
									<span class="source-logo" :class="review.source" />
									<strong>{{ review.firstName }}</strong>
									<span v-if="review.date">
										{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
									</span>
								</div>
							</div>
						</div>
						<div class="carousel-controls">
							<button
								v-for="(_, index) in items"
								:key="index"
								:class="{ active: selectedScrollSnap === index }"
								@click="scrollCarouselTo(index)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row readmore">
			<nuxt-link :to="localePath('reviews')" class="button no-fill" target="_self">
				{{ button }}
			</nuxt-link>
		</div>
	</section>
</template>

<script setup>
import emblaCarouselVue from 'embla-carousel-vue';

const config = useRuntimeConfig();

const props = defineProps({
	items: { type: Array, default: () => [] },
	header: { type: String, default: '' },
	button: { type: String, default: '' },
});

const getReviewTotalAverage = () => {
	const sum = props.items
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / props.items.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: props.items.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});

const [emblaCarousel, emblaApi] = emblaCarouselVue({ loop: true, duration: 20 });
const selectedScrollSnap = ref(0);

const scrollCarouselTo = (index) => {
	emblaApi.value.scrollTo(index);
	selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
};

onMounted(() => {
	if (!emblaApi.value) {
		return;
	}

	emblaApi.value.on('select', () => {
		selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
	});
});

const scrollPrev = () => {
	if (emblaApi.value) {
		emblaApi.value.scrollPrev();
		selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
	}
};

const scrollNext = () => {
	if (emblaApi.value) {
		emblaApi.value.scrollNext();
		selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
	}
};
</script>

<style lang="scss" scoped>
.main-content {
	position: relative;
	padding: 0;
	padding-top: 100px;
	background-color: var(--brown-light);
}

.row {
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.column12 {
	display: flex;
	justify-content: center;
}

.slider-background {
	display: flex;
	background: var(--secondary-background-color);
	background-image: url('~/assets/images/mu-hotel-spa.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	max-width: 1024px;
	margin: auto;
}

.review-slider {
	width: 60%;
	margin: auto;
	overflow: hidden;
	backdrop-filter: blur(10px);
	background: rgb(255 255 255 / 40%);
	margin-top: 70px;
	margin-bottom: 70px;
	position: relative;

	.carousel-container {
		display: flex;
	}

	.carousel-controls {
		display: flex;
		justify-content: center;
		position: absolute;
		left: 50%;
		bottom: 10%;
		transform: translateX(-50%);

		button {
			all: unset;
			display: inline-block;
			cursor: pointer;
			width: 9px;
			height: 9px;
			background: none;
			border: 1px solid #fff;
			margin: 0 5px;
			box-shadow: none;

			&.active {
				background: #fff;
				opacity: 1;
			}
		}
	}

	.review-slide-content {
		flex: 0 0 100%;
		min-width: 0;
		padding: 25px 70px;
		flex-direction: column;
		text-align: left;
		user-select: none;
		margin-bottom: 80px;

		.border {
			height: 1px;
			width: 100%;
			background-color: #efefef;
			margin: 10px 0 20px;
		}

		.source {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 10px;

			span,
			strong {
				margin: 0 4px 0 0;
			}

			.source-logo {
				width: 40px;
				height: 40px;
				background-size: 40px 40px;

				&.tripadvisor {
					background-image: url('~/assets/images/review-logo-tripadvisor.png');
				}

				&.bookingcom {
					background-image: url('~/assets/images/review-logo-bookingcom.png');
				}

				&.zoover {
					background-image: url('~/assets/images/review-logo-zoover.png');
				}

				&.expedia {
					background-image: url('~/assets/images/review-logo-expedia.png');
				}

				&.google {
					background-image: url('~/assets/images/review-logo-google.png');
				}
			}
		}
	}
}

.slider-buttons-container {
	height: 0;
	max-width: 1024px;
	margin: auto;
	width: 100%;
	justify-content: space-between;
	position: absolute;
	z-index: 30;
	background-color: red;
	top: calc(50% - 25px);

	.embla-prev,
	.embla-next {
		background: rgba(255 255 255 / 90%);
		color: var(--body-color);
		border: none;
		cursor: pointer;
		width: 50px;
		aspect-ratio: 1/1;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: relative;
	}
}

.slider-buttons {
	width: calc(60% + 50px);
	margin: auto;
	display: flex;
	justify-content: space-between;
}

.readmore {
	padding: 30px;
}

.splitbackground {
	position: absolute;
	left: 0;
	right: 0;
	height: 40%;
	background-color: var(--blue-background);
	bottom: 0;
}

@media (max-width: 1080px) {
	.slider-buttons {
		width: calc(70% + 25px);
	}

	.review-slider {
		width: 70%;

		.review-slide-content {
			padding: 25px 50px;

			div {
				flex-wrap: wrap;
				gap: 10px;
				margin: 0 30px;
				padding: 0 10%;
			}
		}
	}
}

@media (max-width: 880px) {
	.main-content {
		padding-top: 60px;
	}
}

@media (max-width: 680px) {
	.slider-buttons {
		width: calc(80% + 20px);
	}

	.review-slider {
		width: calc(80% + 20px);

		.review-slide-content {
			padding: 25px 30px;
		}
	}
}

@media (max-width: 680px) {
	.slider-buttons-container {
		top: unset;
		bottom: 220px;
	}

	.slider-buttons {
		width: calc(80% + 20px);
	}

	.review-slider {
		width: calc(80% + 20px);

		.review-slide-content {
			padding: 25px 30px;

			div {
				flex-wrap: wrap;
				gap: 10px;
				margin: 0 10px;
			}
		}
	}
}

@media (max-width: 480px) {
	.slider-buttons {
		width: 90%;
	}

	.review-slider {
		width: 80%; // Ensures slider width adjusts to 100% of the container
		box-sizing: border-box; // Include padding in the width calculation to avoid overflow
		overflow: hidden; // Ensures content doesn't exceed container width

		.review-slide-content {
			padding: 15px 10px; // Adjust padding to avoid overflow on small screens
		}
	}
}
</style>
